<style lang="scss" scoped>
</style>
<template>
    <div>
        <el-table
            :data="list" style="width: 100%">
            <el-table-column label="序号" type="index" width="160">
            </el-table-column>
            <el-table-column prop="destCityName" label="名称">
            </el-table-column>
            <el-table-column label="产品数量" prop="cityProductCount">
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="200">
                <template slot-scope="scope">
                    <el-popconfirm
                        title="确定置顶吗？"
                        v-if="!scope.row.isTop"
                        @confirm="handleUpdate(scope.row,1)">
                        <el-button style="margin-right: 10px" size="medium" type="text" slot="reference">置顶</el-button>
                    </el-popconfirm>
                    <el-popconfirm
                        v-else
                        title="确定取消置顶吗？" @confirm="handleUpdate(scope.row,0)">
                        <el-button size="medium" type="text" slot="reference">取消置顶</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script type="text/ecmascript-6">
import {getProductServiceCityList, updateCityTop} from '@/www/urls/hotCity'
export default {
    //定义模版数据
    data() {
        return {
            form: {
                pageIndex: 1,
                pageSize: 8
            },
            list: []
        }
    },
    //主件被加载完成
    mounted() {
        this.getData();
    },
    computed: {
        isTopNum() {
            return this.list.filter(({isTop}) => isTop === 1).length
        }
    },
    //定义事件方法
    methods: {
        async getData() {
            const ret = await getProductServiceCityList();
            if (ret.success) {
                this.list = ret.data
            }
        },
        async handleUpdate({destCityId}, isTop) {
            if (isTop === 1 && this.isTopNum === 2)
                return this.$message.error("最多置顶2个城市!")
            const ret = await updateCityTop({
                shortCode: destCityId,
                isTop
            });
            if (ret.success && ret.data) {
                this.$message.success(isTop === 1 ? "置顶成功!" : '取消置顶成功！')
                this.getData();
            } else this.$message.error(ret.errors[0].message)
        }
    }
}
</script>