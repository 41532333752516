<style lang="scss" scoped>
</style>
<template>
    <el-main class="com-eTrip-section section-has-footer">
        <section-content class="driver-section-cont">
            <el-tabs v-model="activeName">
                <el-tab-pane v-for="vm in tabs" :label="vm.title" :name="vm.title" :key="vm.title">
                    <component :is="vm.component" v-if="vm.title===activeName"></component>
                </el-tab-pane>
            </el-tabs>
        </section-content>
    </el-main>
</template>

<script type="text/ecmascript-6">
import BannerManage from './BannerManage'
import SeasonalSelectionManage from './SeasonalSelectionManage'
import HostCityManage from './HostCityManage'
export default {
    //定义模版数据
    data() {
        return {
            activeName: "Banner管理",
            tabs: [
                {
                    title: "Banner管理",
                    component: BannerManage
                },
                {
                    title: "热门目的地",
                    component: HostCityManage
                },
                {
                    title: "当季精品",
                    component: SeasonalSelectionManage
                }
            ]
        }
    }
}
</script>