import http from "@/common/js/http";

export const addProductChosen = async (form) => {
    return await http(`/galaxyProduct/addProductChosen`, form, 'POST');
};

export const getProductChosenList = async (form) => {
    return await http(`/galaxyProduct/productChosenList`, form, 'POST');
};

export const updateProductChosen = async (form) => {
    return await http(`/galaxyProduct/updateProductChosen`, form, 'POST');
};
