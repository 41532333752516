<style lang="scss" scoped>
</style>
<template>
    <div>
        <add-banner-dialog ref="addDialog" @reload="getData"/>
        <el-table
            :data="tableData"
            style="width: 100%">
            <el-table-column label="序号" type="index"/>
            <el-table-column label="产品编号" prop="productNo" width="100"/>
            <el-table-column prop="productName" label="产品简介" width="200"/>
            <el-table-column prop="productRecommendInfo" label="推荐描述"/>
            <el-table-column label="图片">
                <template slot-scope="scope">
                    <el-image style="width: 100px" :src="scope.row.adUrl"
                              :preview-src-list="[scope.row.adUrl]"/>
                </template>
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="200">
                <template slot-scope="scope">
                    <el-button
                        style="margin-right: 10px"
                        size="mini"
                        type="warning"
                        @click="handleEdit(scope.row)">编辑
                    </el-button>
                    <el-popconfirm
                        title="确定删除吗？" @confirm="handleDelete(scope.row)">
                        <el-button size="mini" type="danger" slot="reference">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script type="text/ecmascript-6">
import AddBannerDialog from './add-banner-dialog'
import {getProductChosenList, updateProductChosen} from '@/www/urls/recommendedProduct'
import {bannerTypes} from '@/data/index'
export default {
    //定义模版数据
    data() {
        return {
            bannerTypes,
            tableData: []
        }
    },
    components: {
        AddBannerDialog
    },
    //计算属性
    computed: {},
    //主件被加载完成
    mounted() {
        this.getData();
    },
    //定义事件方法
    methods: {
        async getData() {
            const ret = await getProductChosenList();
            if (ret.success) {
                this.tableData = ret.data
            }
        },
        async handleEdit(row) {
            this.$refs['addDialog'].show(row)
        },
        async handleDelete({id}) {
            const ret = await updateProductChosen({
                id,
                isDelete: 1
            })
            if (ret.success && ret.data) {
                this.$message.success("删除成功！")
                this.getData()
            } else this.$message.error(ret.errors[0].message)
        }
    },
    //监听模版变量
    watch: {}

}
</script>