<style lang="scss" scoped>
</style>
<template>

    <div>
        <el-button size="medium" style="margin-bottom: 10px" type="primary" @click="dialogVisible=true">新增Banner
        </el-button>
        <el-dialog
            title="小程序Banner设置"
            :visible.sync="dialogVisible"
            width="600px"
            :before-close="handleClose">
            <el-form :model="form" :rules="rules" ref="form" label-width="100px">
                <el-form-item label="类型：" prop="bannerType">
                    <el-select style="width: 300px" v-model="form.bannerType">
                        <el-option v-for="vm in bannerTypes" :key="vm.value"
                                   :label="vm.label" :value="vm.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="大标题：" prop="title">
                    <el-input style="width: 300px" placeholder="大标题" maxlength="20" v-model="form.title"/>
                </el-form-item>
                <el-form-item label="小标题：" prop="titleDetail">
                    <el-input style="width: 300px" placeholder="小标题" maxlength="50" v-model="form.titleDetail"/>
                </el-form-item>
                <el-form-item label="内容链接：" prop="contentInfo">
                    <el-input style="width: 300px" placeholder="内容链接" v-model="form.contentInfo"/>
                </el-form-item>
                <el-form-item label="图片：" required prop="bannerUrl">
                    <ImageUpload @update:imageUploadCompleted="handleImageUpload"
                                 :images="[{imageUrl:form.bannerUrl,showImage:true}]"/>
                    <span style="margin-left: 10px;"> 建议上传345*170px的.jpg/png/gif</span>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
    <el-button @click="handleClose">取 消</el-button>
    <el-button type="primary" @click="handleSubmit">确 定</el-button>
  </span>
        </el-dialog>
    </div>

</template>

<script type="text/ecmascript-6">
import ImageUpload from '@/components/imageUpload'
import {bannerTypes} from '@/data/index'
import {addBanner, updateBanner} from '@/www/urls/banner'
export default {
    //定义模版数据
    data() {
        return {
            dialogVisible: false,
            bannerTypes,
            form: {
                bannerType: bannerTypes[0].value,//0用车 1旅游
                bannerUrl: '',
                title: '',
                titleDetail: '',
                contentInfo: ''
            },
            rules: {
                bannerUrl: [{required: true, message: '请上传banner图片'}]
            }
        }
    },
    components: {
        ImageUpload
    },
    //定义事件方法
    methods: {
        show(data) {
            for (let key in data) {
                this.form[key] = data[key];
            }
            this.dialogVisible = true;
        },
        handleClose() {
            this.dialogVisible = false;
            this.$refs['form'].resetFields()
        },
        async handleSubmit() {
            try {
                await this.$refs['form'].validate();
                let ret;
                if (!this.form.id)
                    ret = await addBanner(this.form)
                else
                    ret = await updateBanner(this.form)
                if (ret.success) {
                    this.dialogVisible = false;
                    this.$emit('reload');
                    this.$message.success(this.form.id ? '编辑成功！' : '添加成功！')
                    this.$refs['form'].resetFields()
                }
            } catch (err) {
                console.log(err);
            }
        },
        handleImageUpload(images) {
            this.form.bannerUrl = images[0].imageUrl;
        }
    },
    //监听模版变量
    watch: {}

}
</script>