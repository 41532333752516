<style lang="scss" scoped>
</style>
<template>

    <div>
        <el-button size="medium" style="margin-bottom: 10px" type="primary" @click="handleAdd">
            推荐产品设置
        </el-button>
        <el-dialog
            title="推荐产品设置"
            :visible.sync="dialogVisible"
            width="640px"
            :before-close="handleClose">
            <el-form :model="form" :rules="rules" ref="form" label-width="160px">
                <el-form-item label="产品ID：" prop="productNo">
                    <el-input style="width: 400px" v-model="form.productNo" placeholder="请填入产品编号"/>
                </el-form-item>
                <el-form-item label="产品简称(10个汉字)：" prop="productName">
                    <el-input style="width: 400px" placeholder="产品简称" maxlength="10" v-model="form.productName"/>
                </el-form-item>
                <el-form-item label="推荐描述(20个汉字)：" prop="productRecommendInfo">
                    <el-input type="textarea" style="width: 400px" maxlength="20" placeholder="推荐描述"
                              v-model="form.productRecommendInfo"/>
                </el-form-item>
                <el-form-item label="广告封面：" required prop="adUrl">
                    <ImageUpload @update:imageUploadCompleted="handleImageUpload"
                                 :images="[{imageUrl:form.adUrl,showImage:true}]"/>
                    <span style="margin-left: 10px">建议上传336x226px的.jpg/png/gif</span>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
    <el-button @click="handleClose">取 消</el-button>
    <el-button type="primary" @click="handleSubmit">确 定</el-button>
  </span>
        </el-dialog>
    </div>

</template>

<script type="text/ecmascript-6">
import ImageUpload from '@/components/imageUpload'
import {addProductChosen, updateProductChosen} from '@/www/urls/recommendedProduct'
export default {
    //定义模版数据
    data() {
        return {
            dialogVisible: false,
            form: {
                adUrl: '',
                productNo: '',
                productName: '',
                productRecommendInfo: ''
            },
            rules: {
                adUrl: [{required: true, message: '请上传图片'}],
                productNo: [{required: true, message: '请填写产品编号'}],
                productName: [{required: true, message: '请填写产品简称'}],
                productRecommendInfo: [{required: true, message: '请填写推荐描述'}]
            }
        }
    },
    components: {
        ImageUpload
    },
    //定义事件方法
    methods: {
        handleAdd() {
            this.form = {
                adUrl: '',
                productNo: '',
                productName: '',
                productRecommendInfo: ''
            }
            this.dialogVisible = true
        },
        show(data) {
            for (let key in data) {
                this.form[key] = data[key];
            }
            this.dialogVisible = true;
        },
        handleClose() {
            this.dialogVisible = false;
            this.$refs['form'].resetFields()
        },
        async handleSubmit() {
            try {
                await this.$refs['form'].validate();
                let ret;
                if (!this.form.id)
                    ret = await addProductChosen(this.form)
                else
                    ret = await updateProductChosen(this.form)
                if (ret.success) {
                    this.dialogVisible = false;
                    this.$emit('reload');
                    this.$message.success(this.form.id ? '编辑成功！' : '添加成功！')
                    this.$refs['form'].resetFields()
                }
            } catch (err) {
                console.log(err);
            }
        },
        handleImageUpload(images) {
            this.form.adUrl = images[0].imageUrl;
        }
    },
    //监听模版变量
    watch: {}

}
</script>