import http from "@/common/js/http";

export const addBanner = async (form) => {
    return await http(`/galaxyBanner/addBanner`, form, 'POST');
};

export const getBannerList = async (form) => {
    return await http(`/galaxyBanner/getBannerList`, form, 'POST');
};
export const getBannerListByPram = async () => {
    return await http(`/galaxyBanner/getBannerListByPram`);
};
export const updateBanner = async (form) => {
    return await http(`/galaxyBanner/updateBanner`, form, 'POST');
};
